



























import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["dates"]),
  },
  methods: {
    formatText(text: string) {
      text = text.replaceAll("[PROMOSTART]", this.dates.promoStartDate);
      text = text.replaceAll("[PROMOEND]", this.dates.promoEndDate);
      text = text.replaceAll("[OLDEST]", this.dates.dateOldest);
      text = text.replaceAll("[YOUNGEST]", this.dates.dateYoungest);

      return text;
    },
  },
  data: () => ({
    articles: [
      {
        title: "Durée de l'opération",
        text: `<br/>
        L'opération est limitée dans le temps.<br/>
        Elle débute le [PROMOSTART] et se termine le [PROMOEND].<br/>
        La dématérialisation des coupons sera impossible après cette date.`,
      },
      {
        title: "Utilisation du coupon",
        text: `<br/>Les coupons permettent de participer au paiement de l'inscription, de la licence ou de la cotisation des activités culturelles et sportives.<br/>
          Le coupon est nominatif, sa valeur est de 20€.<br/>
          Il ne sera délivré qu'<strong>un seul coupon par enfant</strong>.<br/>
          Le coupon accompagné de son accusé de réception sera transmis à l'association lors de l'inscription de l'enfant.`,
      },
      {
        title: "Les bénéficiaires",
        text: `<br/>Les bénéficiaires de l'opération sont les enfants nés entre le <strong>[OLDEST] et le [YOUNGEST]</strong> et habitants sur le territoire de la Communauté des Communes du Quercy Caussadais.<br/>
          Les communes du territoire sont : Auty, Caussade, Cayrac, Cayriech, Labastide de Penne, Lapenche, Lavaurette, Mirabel, Molières, Montalzat, Monteils, Montfermier, Montpezat de Quercy, Puylaroque, Réalville, Saint-Cirq, Saint-Georges, Saint-Vincent d'Autejac, Septfonds`,
      },
      {
        title: "Les justificatifs",
        text: `<br/>Cette opération est soumise, lors de l'inscription, à la transmission obligatoire de justificatifs.<br/>Les justificatifs sont les suivants :<br/>
        <ul>
        <li>Carte Nationale d'Identité de l'Enfant, le cas échéant la copie intégrale du livret de famille</li>
        <li>Un justificatif de domicile de l'année en cours (factures d'eau, électricité, téléphonie et internet</li>
        </ul><br/>
        Le demandeur du coupon certifie sur l'honneur l'exactitude des informations et des documents fournis.`,
      },
      {
        title: "Les associations",
        text: `<br/>Les associations doivent être situées sur le territoire du Quercy Caussadais (voir la liste à l'article 3).<br/>
        Les associations extérieures au territoire seront acceptées si la discipline qu'elles proposent n'a pas d'équivalent sur le territoire de la Communauté de Communes du Quercy Caussadais<br/><br/>
        Si vous souhaitez changer d'activité après la validation du coupon, vous ne pouvez pas le faire depuis le site.  Il vous faut contacter la Communauté de Communes seule habilitée à effectuer cette opération
        `,
      },
      {
        title: "Respect des règles",
        text: `<br/>Le non respect des règles pré-citées entrainera l'annulation de la délivrance du coupon par nos services<br/>
        Nos services se réservent également le droit de sanctionner toute tentative de fraude`,
      },
    ],
  }),
});
